import { Button, CircularProgress, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, FieldArray, Form, Formik, FormikErrors } from 'formik'
import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  useCreateSharedListMutation,
  useCreateSharedListItemsMutation,
} from '@src/mutations/SharedListMutations.generated'
import { SharedListFragment } from '@src/queries/SharedListQueries.generated'
import messages from '@utils/messages'
import LiquidCheckboxAdapter from '../LiquidCheckboxAdapter'
import LiquidInputAdapter from '../LiquidInputAdapter'
import ResponsiveModal, {
  ResponsiveModalBody,
  ResponsiveModalActions,
} from '../ResponsiveModal/ResponsiveModal'
import LinkStyleButton, {
  LinkStyleButtonSizes,
} from '@src/components/LinkStyleButton'
import AddIcon from '@material-ui/icons/Add'
import {
  useGlobalSnackbar,
  SnackbarMessages,
  SnackbarType,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { useValidators } from '@src/utils/validators'
import clsx from 'clsx'
import { SharedListEventAction } from '@utils/analytics/enums'
import { ProductCardType } from '@src/utils/searchUtils'
import { getPricingAndAvailabilityEventAction } from '@src/utils/analytics/pricingAndAvailability'
import { sendSharedListEvent } from '@src/utils/analytics'
import { getListName } from '@src/routes/SharedList/utils'
import { CartItemType } from '@src/types/graphql-types'
import { isPast } from 'date-fns-next'

const useStyles = makeStyles((theme: Theme) => ({
  modalForm: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  modalScrollSection: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: theme.spacing(4),
  },
  modalMainContent: {
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
  },
  modalFooter: {
    paddingTop: 0,
  },
  addNewListContainer: {
    position: 'relative',
  },
  newListFields: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& div': {
      flex: '1 1 auto',
    },
    '& label': {
      marginRight: 0,
    },
    '& .MuiCheckbox-root': {
      minWidth: 36,
    },
  },
  createListButtonContainer: {
    position: 'absolute',
    top: theme.spacing(1),
  },
  invisible: { visibility: 'hidden' },
  noListsHeader: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  noListsDescription: {
    marginBottom: theme.spacing(4),
  },
}))

interface AddToListDialogProps {
  open: boolean
  onClose: () => void
  materialNumber: string
  materialId?: string | null
  productName?: string
  productId?: string
  brand?: string
  lists: SharedListFragment[]
  quantity: number | string
  refetchLists: () => void
  userId: string | undefined
  sendAddToListEvent?: (action: SharedListEventAction, label: string) => void
  carouselType?: ProductCardType
  type?: CartItemType | null
}

interface AddToListDialogFormInput {
  isAddNewListItemSelected: boolean
  newSharedListItem: string
  lists: { listId: string; checked: boolean }[]
}

const AddToListDialog: React.FC<AddToListDialogProps> = ({
  open,
  onClose,
  materialNumber,
  materialId,
  productName,
  productId,
  brand,
  type,
  lists,
  quantity,
  refetchLists,
  userId,
  sendAddToListEvent,
  carouselType,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const { required } = useValidators()
  const [isCreatingList, setIsCreatingList] = useState(false)
  const [createSharedList] = useCreateSharedListMutation()
  const [createSharedListItems] = useCreateSharedListItemsMutation()
  const { setSnackbar, setGlobalSnackbarState } = useGlobalSnackbar()

  const visibleLists = lists.filter((list) => {
    const isOwner = list.ownerId === userId
    const isPastDeadline = Boolean(list.deadline && isPast(list.deadline))

    return !isPastDeadline || isOwner
  })

  const hasLists = visibleLists.length > 0

  const addMaterial = async (listIds: string[]) => {
    try {
      await createSharedListItems({
        variables: {
          listIds,
          listItems: [
            {
              materialNumber,
              materialId,
              quantity: Number(quantity),
              type,
            },
          ],
        },
      })
      return true
    } catch {
      return false
    }
  }

  const createList = async (name: string): Promise<boolean> => {
    try {
      await createSharedList({
        variables: {
          input: {
            name,
            invitations: [],
            sharedListItems: [
              {
                materialNumber,
                materialId,
                quantity: Number(quantity),
                type,
              },
            ],
          },
        },
      })
      return true
    } catch {
      return false
    }
  }

  const onSubmit = async (input: AddToListDialogFormInput) => {
    let materialAdded: Promise<boolean> | undefined,
      newList: Promise<boolean> | undefined
    let action: string

    const incompleteGA4Payload = {
      action: 'add to list',
      section: 'select list',
      component: 'modal',
      elementType: 'button',
      elementText: 'add to list',
      coreEvent: 'no',
      productId,
      productBrand: brand,
      productVariant: materialNumber,
      productName,
    }

    if (isCreatingList && input.isAddNewListItemSelected) {
      newList = createList(input.newSharedListItem)
      // ua event (not ga4)
      sendAddToListEvent &&
        sendAddToListEvent(SharedListEventAction.CreateList, userId || '')

      action = getPricingAndAvailabilityEventAction(
        SharedListEventAction.CreateList,
        carouselType
      )
      sendSharedListEvent(
        {
          ...incompleteGA4Payload,
          detail: 'newly create list',
        },
        {
          eventCategory: 'lists page',
          eventAction: action,
          eventLabel: userId || '',
          eventInteractionType: 0,
        }
      )
    } else {
      // ua event (not ga4)
      sendAddToListEvent &&
        sendAddToListEvent(SharedListEventAction.AddToList, materialNumber)

      action = getPricingAndAvailabilityEventAction(
        SharedListEventAction.AddToList,
        carouselType
      )
      sendSharedListEvent(
        {
          ...incompleteGA4Payload,
        },
        {
          eventCategory: 'lists page',
          eventAction: action,
          eventLabel: materialNumber,
          eventInteractionType: 0,
        }
      )
    }

    const selectedListIds = input.lists
      .filter((list) => list.checked)
      .map((list) => list.listId)

    if (selectedListIds.length > 0) {
      materialAdded = addMaterial(selectedListIds)
    }

    const [materialAddedRes, newListRes] = await Promise.all([
      materialAdded,
      newList,
    ])
    if (!newListRes && !materialAddedRes) {
      setSnackbar(SnackbarMessages.GenericError)
      return
    }

    if (materialAddedRes === false && newListRes) {
      setGlobalSnackbarState({
        open: true,
        message: <FormattedMessage {...messages.ADD_ITEMS_TO_LIST_FAILED} />,
        variant: SnackbarType.ErrorAlert,
      })
      refetchLists()
      onClose()
      return
    }

    if (newListRes === false && materialAddedRes) {
      setGlobalSnackbarState({
        open: true,
        message: <FormattedMessage {...messages.CREATE_SHARED_LIST_FAILED} />,
        variant: SnackbarType.ErrorAlert,
      })
      onClose()
      return
    }

    refetchLists()
    setGlobalSnackbarState({
      open: true,
      message: <FormattedMessage {...messages.SHARED_LIST_ITEM_ADDED} />,
      variant: SnackbarType.Success,
    })
    onClose()
  }

  const validate = (values: AddToListDialogFormInput) => {
    const errors: FormikErrors<AddToListDialogFormInput> = {}

    const newSharedListItemError =
      values.isAddNewListItemSelected && required(values.newSharedListItem)
    if (newSharedListItemError) {
      errors.newSharedListItem = newSharedListItemError
    }

    return errors
  }

  useEffect(() => {
    if (!open) setIsCreatingList(false)
  }, [open])

  return (
    <ResponsiveModal
      open={open}
      onClose={() => onClose()}
      renderTitle={() => (
        <Typography variant="h2">
          <FormattedMessage {...messages.SELECT_LIST} />
        </Typography>
      )}
    >
      <Formik<AddToListDialogFormInput>
        initialValues={{
          isAddNewListItemSelected: false,
          newSharedListItem: '',
          lists: visibleLists.map(({ listId }) => ({ listId, checked: false })),
        }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(formikBag) => {
          const { isSubmitting, values } = formikBag
          const hasSelectedLists = values.lists.some((list) => list.checked)

          return (
            <Form className={classes.modalForm}>
              <div className={classes.modalScrollSection}>
                <ResponsiveModalBody className={classes.modalMainContent}>
                  {hasLists ? (
                    <FieldArray name="lists">
                      {() =>
                        visibleLists.map((list, index) => (
                          <div
                            key={index}
                            data-testid={`lists_name-${getListName(
                              intl.formatMessage
                            )(list)}`}
                          >
                            <Field
                              name={`lists[${index}].checked`}
                              component={LiquidCheckboxAdapter}
                              label={getListName(intl.formatMessage)(list)}
                            />
                          </div>
                        ))
                      }
                    </FieldArray>
                  ) : (
                    <div>
                      <Typography
                        variant="h3"
                        className={classes.noListsHeader}
                      >
                        <FormattedMessage {...messages.NO_LISTS_YET_HEADER} />
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.noListsDescription}
                      >
                        <FormattedMessage
                          {...messages.NO_LISTS_YET_DESCRIPTION}
                        />
                      </Typography>
                    </div>
                  )}

                  <div className={classes.addNewListContainer}>
                    <div
                      className={clsx(classes.newListFields, {
                        [classes.invisible]: !isCreatingList,
                      })}
                    >
                      {hasLists && (
                        <Field
                          name="isAddNewListItemSelected"
                          component={LiquidCheckboxAdapter}
                        />
                      )}
                      <Field
                        key={String(isCreatingList)}
                        autoFocus={isCreatingList} // eslint-disable-line jsx-a11y/no-autofocus
                        filled
                        name="newSharedListItem"
                        component={LiquidInputAdapter}
                        placeholder={intl.formatMessage(
                          messages.ENTER_NEW_LIST_NAME
                        )}
                      />
                    </div>
                    <div
                      className={clsx(classes.createListButtonContainer, {
                        [classes.invisible]: isCreatingList,
                      })}
                    >
                      <LinkStyleButton
                        size={
                          hasLists
                            ? LinkStyleButtonSizes.Medium
                            : LinkStyleButtonSizes.Large
                        }
                        onClick={() => {
                          formikBag.setFieldValue(
                            'isAddNewListItemSelected',
                            true
                          )
                          setIsCreatingList(true)
                        }}
                      >
                        <AddIcon />
                        <FormattedMessage {...messages.CREATE_LIST} />
                      </LinkStyleButton>
                    </div>
                  </div>
                </ResponsiveModalBody>
              </div>

              <ResponsiveModalBody className={classes.modalFooter}>
                <ResponsiveModalActions>
                  <Button
                    size="large"
                    color="primary"
                    variant="outlined"
                    onClick={() => onClose()}
                  >
                    <FormattedMessage {...messages.CANCEL} />
                  </Button>
                  <Button
                    type="submit"
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={
                      isSubmitting ||
                      (!hasSelectedLists && !values.isAddNewListItemSelected)
                    }
                    startIcon={
                      isSubmitting && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          thickness={5}
                        />
                      )
                    }
                    data-testid="lists_confirm-add-to-list-modal-button"
                  >
                    <FormattedMessage {...messages.ADD_TO_LIST} />
                  </Button>
                </ResponsiveModalActions>
              </ResponsiveModalBody>
            </Form>
          )
        }}
      </Formik>
    </ResponsiveModal>
  )
}

export default AddToListDialog
