import {
  SharedListDetailFragment,
  SharedListFragment,
} from '@src/queries/SharedListQueries.generated'
import { IntlFormatters } from 'react-intl'
import messages from '@utils/messages'

export const getListName =
  (formatMessage: IntlFormatters['formatMessage']) =>
  (list: SharedListFragment | SharedListDetailFragment): string => {
    return list.name === 'SYSTEM_CREATED_LIST'
      ? formatMessage(messages.LIST_NUM_ONE)
      : list.name
  }

export const validateNotAMember = (
  value: string,
  list: SharedListDetailFragment,
  ccEmailArray: (value: string) => string[],
  formatMessage: IntlFormatters['formatMessage']
): string | undefined => {
  const emails = ccEmailArray(value)
  const memberEmail = emails.find(
    (email) =>
      list.ownerDetail.email === email ||
      list.membersDetail?.some((m) => m.email === email)
  )
  return memberEmail
    ? formatMessage(messages.ALREADY_A_MEMBER_ERROR, { email: memberEmail })
    : undefined
}
