import Icon, { BaseIconProps } from './Icon'
function CarouselArrowRightIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20" {...props}>
        <path d="M1 0a1 1 0 0 1 .78.35l7.94 9a1 1 0 0 1 0 1.3l-7.94 9a1 1 0 0 1-1.45.11 1 1 0 0 1-.11-1.41L7.61 10 .25 1.65A1 1 0 0 1 .36.24 1 1 0 0 1 1 0" />
      </svg>
    </Icon>
  )
}
export default CarouselArrowRightIcon
