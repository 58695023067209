import Icon, { BaseIconProps } from './Icon'
function CarouselArrowLeftIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 20" {...props}>
        <path d="M9 20a1 1 0 0 1-.78-.35l-7.94-9a1 1 0 0 1 0-1.3l7.94-9A1 1 0 0 1 9.64.24a1 1 0 0 1 .11 1.41L2.39 10l7.36 8.35a1 1 0 0 1-.11 1.41A1 1 0 0 1 9 20" />
      </svg>
    </Icon>
  )
}
export default CarouselArrowLeftIcon
