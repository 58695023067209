import React from 'react'
import { Box } from '@material-ui/core'
import MUILink from '@material-ui/core/Link'
import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import { support } from '@src/routes'
import { Link } from '@src/components/Link'
import { useCurrentUser } from '@utils/useCurrentUser'

interface ExpiryDateProps {
  expirationDate: string | null | undefined
  displayColon?: boolean
  displayInline?: boolean
  outerClassName?: string
  labelClassName?: string
}

const ExpiryDate: React.FC<ExpiryDateProps> = ({
  expirationDate,
  displayColon,
  displayInline,
  outerClassName,
  labelClassName,
}) => {
  const { userIsLoggedIn } = useCurrentUser()
  if (!expirationDate || !userIsLoggedIn) {
    return null
  }
  return (
    <Box className={outerClassName}>
      <span className={labelClassName}>
        <FormattedMessage id="EXPIRY_DATE" defaultMessage="Expiry Date" />
        {displayColon ? ': ' : ''}
      </span>
      <Box display={displayInline ? 'inline' : 'block'}>
        {expirationDate === 'NA' ? (
          <Link href={support.customerSupport()} passHref>
            <MUILink color="primary" target="_blank">
              <FormattedMessage
                id="CONTACT_CUSTOMER_SERVICE_FIRST_UPPERCASE"
                defaultMessage="Contact Customer Service"
              />
            </MUILink>
          </Link>
        ) : (
          dayjs(expirationDate).format('MMMM DD, YYYY')
        )}
      </Box>
    </Box>
  )
}

export default ExpiryDate
