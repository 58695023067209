import React from 'react'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import messages from '@src/utils/messages'
import { TrackedAvailabilityMessage } from '../TrackedAvailabilityMessage/TrackedAvailabilityMessage'

const useStyles = makeStyles(() => ({
  availabilityOverwrite: {
    whiteSpace: 'pre-line',
  },
}))

interface AvailabilityOverwrite {
  messageKey?: Maybe<string>
  messageValue?: Maybe<string>
  messageVariable1?: Maybe<string>
  messageVariable2?: Maybe<string>
  messageVariable3?: Maybe<string>
}

interface Props {
  availabilityOverwrite: AvailabilityOverwrite
}

const AvailabilityOverwriteMessage: React.FC<Props> = ({
  availabilityOverwrite,
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Grid className={classes.availabilityOverwrite}>
      {availabilityOverwrite?.messageKey && (
        <TrackedAvailabilityMessage
          {...(messages[availabilityOverwrite.messageKey] || {
            id: availabilityOverwrite.messageKey,
          })}
          values={{
            messageValue: availabilityOverwrite?.messageValue,
            messageVariable1: availabilityOverwrite?.messageVariable1,
            messageVariable2: availabilityOverwrite?.messageVariable2
              ? formatMessage(
                  messages[availabilityOverwrite.messageVariable2] || {
                    id: availabilityOverwrite.messageVariable2,
                  }
                )
              : availabilityOverwrite?.messageVariable2,
            messageVariable3: availabilityOverwrite?.messageVariable3,
          }}
        />
      )}
    </Grid>
  )
}

export default AvailabilityOverwriteMessage
