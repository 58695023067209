import Icon, { BaseIconProps } from './Icon'
function ShipsTodayIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12" {...props}>
        <path d="M20 6.73a1.62 1.62 0 0 0-1.28-1.4 16 16 0 0 0-.83-1.71c-.07-.14-.39-.59-1.43-.59h-.53V1.44A1.42 1.42 0 0 0 14.51 0h-7.6A1.42 1.42 0 0 0 5.5 1.44v.38h1v-.38a.38.38 0 0 1 .38-.39h7.6a.38.38 0 0 1 .38.39v6.19a2.2 2.2 0 0 0-1.38 1.15H11a2.14 2.14 0 0 0-3.88 0h-.21a.38.38 0 0 1-.38-.38v-.75h-1v.74a1.43 1.43 0 0 0 1.35 1.43 2.18 2.18 0 0 0 4.36 0h2a2.18 2.18 0 1 0 4.36 0h1.29A1.21 1.21 0 0 0 20 8.5zM9.06 11a1.18 1.18 0 1 1 1.16-1.18A1.16 1.16 0 0 1 9.06 11m6.86-6.87h.53a1.1 1.1 0 0 1 .56.1c.2.37.39.75.56 1.13h-1.65zM15.46 11a1.18 1.18 0 1 1 1.16-1.18A1.17 1.17 0 0 1 15.46 11m3.47-2.24a.25.25 0 0 1-.18.07h-1.34a2.23 2.23 0 0 0-1.49-1.19V6.36h2.44a.56.56 0 0 1 .6.43v1.78a.26.26 0 0 1-.03.14z" />
        <path d="M9.35 3a.52.52 0 0 0-.51-.53H.51A.52.52 0 0 0 0 3a.51.51 0 0 0 .51.52h8.33A.51.51 0 0 0 9.35 3m0 1.8a.52.52 0 0 0-.51-.53H2a.53.53 0 0 0 0 1.06h6.84a.52.52 0 0 0 .51-.53m0 1.83a.51.51 0 0 0-.51-.52H3.62a.52.52 0 0 0-.52.52.52.52 0 0 0 .52.53h5.22a.52.52 0 0 0 .51-.53" />
      </svg>
    </Icon>
  )
}
export default ShipsTodayIcon
