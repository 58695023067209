import Icon, { BaseIconProps } from './Icon'
function FilterAndSortIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 24 24"
        {...props}
      >
        <path d="M10 18h4v-2h-4zM3 6v2h18V6zm3 7h12v-2H6z" />
      </svg>
    </Icon>
  )
}
export default FilterAndSortIcon
