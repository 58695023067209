import React, { FC, ReactNode, useRef } from 'react'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { ArrowDropDown } from '@material-ui/icons'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles, Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  btnGroup: {
    '& > button:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: `1px solid ${theme.palette.grey['500']}`,
    },
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    margin: theme.spacing(2, 0),
  },
  popperPaper: {
    boxShadow: theme.shadows[4],
    border: `1px solid ${theme.palette.grey['A100']}`,
  },
  menuList: {
    padding: theme.spacing(1, 0),
  },
  menuItem: {
    padding: theme.spacing(3, 4),
  },
  arrowButton: {
    maxWidth: '44px',
  },
}))

interface MultiActionButtonOption {
  label: string
  onClick?: () => void
  renderButton?: () => ReactNode
}

interface MultiActionButtonProps {
  primaryOption: MultiActionButtonOption
  options: MultiActionButtonOption[]
}

const MultiActionButton: FC<MultiActionButtonProps> = ({
  primaryOption,
  options,
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const arrowRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        color="secondary"
        variant="contained"
        ref={anchorRef}
        aria-label={`${primaryOption.label} split button`}
        className={classes.btnGroup}
        fullWidth
      >
        {primaryOption.renderButton ? (
          primaryOption.renderButton()
        ) : (
          <Button size="large" onClick={primaryOption.onClick}>
            {primaryOption.label}
          </Button>
        )}
        <Button
          className={classes.arrowButton}
          size="small"
          aria-controls="split-button-menu"
          aria-expanded={open ? 'true' : 'false'}
          aria-haspopup="menu"
          onClick={handleToggle}
          ref={arrowRef}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        className={classes.popper}
        style={{ width: anchorRef.current?.offsetWidth }}
        open={open}
        anchorEl={anchorRef.current}
        role="tooltip"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.popperPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem
                  className={classes.menuList}
                  onKeyDown={(e) => {
                    if (e.keyCode === 27) {
                      setOpen(false)
                      arrowRef.current?.focus()
                    }
                  }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        if (option.onClick) {
                          option.onClick()
                        }
                        handleToggle()
                      }}
                      className={classes.menuItem}
                    >
                      {option.renderButton
                        ? option.renderButton()
                        : option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default MultiActionButton
