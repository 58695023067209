import Icon, { BaseIconProps } from './Icon'
function FlagIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 18 20"
        {...props}
      >
        <path d="m8.83 2.35.48 2.35h6.29v7.06h-4.03l-.48-2.35H2.4V2.35zM10.8 0H0v20h2.4v-8.23h6.72l.48 2.35H18V2.35h-6.72z" />
      </svg>
    </Icon>
  )
}
export default FlagIcon
