import Icon, { BaseIconProps } from './Icon'
function FreeFreightIcon({ ...props }: BaseIconProps) {
  return (
    <Icon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 9" {...props}>
        <path d="M.7.57v.97h1.22v.57H.71v1.4H0V0h2.16v.57zm4.78 2.94h-.63a.27.27 0 0 1-.26-.14L3.95 2.3a.3.3 0 0 0-.09-.1.3.3 0 0 0-.14-.03h-.24v1.34h-.7V0h1.07a2.1 2.1 0 0 1 .6.08 1.15 1.15 0 0 1 .42.2.8.8 0 0 1 .23.33 1.1 1.1 0 0 1 .08.41 1.1 1.1 0 0 1-.05.33 1 1 0 0 1-.14.29 1 1 0 0 1-.22.23 1.1 1.1 0 0 1-.3.16.7.7 0 0 1 .12.09.6.6 0 0 1 .1.13zM3.84 1.66a.9.9 0 0 0 .3-.04.55.55 0 0 0 .2-.13.5.5 0 0 0 .12-.18.7.7 0 0 0 .03-.23.48.48 0 0 0-.16-.39.73.73 0 0 0-.48-.14h-.37v1.11zM6.66.57v.9h1.13v.55H6.66v.92h1.45v.57H5.95V0h2.16v.57zm2.82 0v.9h1.13v.55H9.48v.92h1.45v.57H8.77V0h2.16v.57zM.7 6.02v.97h1.22v.57H.71v1.4H0V5.45h2.16v.57zm4.78 2.94h-.63a.27.27 0 0 1-.26-.14l-.64-1.08a.3.3 0 0 0-.09-.09.3.3 0 0 0-.14-.03h-.24v1.34h-.7V5.45h1.07a2.1 2.1 0 0 1 .6.07 1.15 1.15 0 0 1 .42.22.8.8 0 0 1 .23.32 1.1 1.1 0 0 1 .08.41 1.1 1.1 0 0 1-.05.33 1 1 0 0 1-.14.29 1 1 0 0 1-.22.22 1.1 1.1 0 0 1-.3.16.7.7 0 0 1 .12.1.6.6 0 0 1 .1.13zM3.84 7.11a.9.9 0 0 0 .3-.04.55.55 0 0 0 .2-.13.5.5 0 0 0 .12-.18.7.7 0 0 0 .03-.24.48.48 0 0 0-.16-.38.73.73 0 0 0-.48-.14h-.37v1.11zm2.82-1.09v.9h1.13v.55H6.66v.92h1.45v.57H5.95V5.45h2.16v.57zm2.82 2.94h-.7V5.45h.7zm2.5-1.79h1.16v1.45a1.8 1.8 0 0 1-.55.29 2.1 2.1 0 0 1-.62.09 2 2 0 0 1-.76-.13 1.7 1.7 0 0 1-.58-.38 1.65 1.65 0 0 1-.37-.57 1.9 1.9 0 0 1-.13-.72 2 2 0 0 1 .13-.72 1.6 1.6 0 0 1 .91-.94 1.9 1.9 0 0 1 .74-.13 2 2 0 0 1 .39.03 2 2 0 0 1 .33.1 1.6 1.6 0 0 1 .52.33l-.2.33a.2.2 0 0 1-.12.1.22.22 0 0 1-.17-.05 3 3 0 0 0-.16-.1 1 1 0 0 0-.17-.07 1 1 0 0 0-.2-.04 2 2 0 0 0-.25-.02 1 1 0 0 0-.43.08.9.9 0 0 0-.32.24 1.1 1.1 0 0 0-.2.38 1.5 1.5 0 0 0-.08.48 1.6 1.6 0 0 0 .08.52 1.1 1.1 0 0 0 .22.39.95.95 0 0 0 .35.24 1.3 1.3 0 0 0 .75.05 1.5 1.5 0 0 0 .25-.1v-.6h-.38a.15.15 0 0 1-.1-.03.13.13 0 0 1-.04-.1zm4.77-1.72v3.51h-.7V7.45h-1.5v1.51h-.7V5.45h.7v1.49h1.5v-1.5zm3.25.58h-.99v2.93h-.7V6.03h-1v-.58H20z" />
      </svg>
    </Icon>
  )
}
export default FreeFreightIcon
