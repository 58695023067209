// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type MaterialDetailQueryVariables = Types.Exact<{
  materialNumber: Types.Scalars['String'];
  catalogType?: Types.InputMaybe<Types.CatalogType>;
  filter?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type MaterialDetailQuery = { __typename?: 'Query', getMaterialDetail?: { __typename?: 'Material', id: string, number: string, name?: string | null, isMarketplace?: boolean | null, displaySellerName?: string | null, marketplaceOfferId?: string | null, marketplaceSellerId?: string | null, description?: string | null, product: string, packageSize?: string | null, shipsToday?: boolean | null, freeFreight?: boolean | null, linearFormula?: string | null, empiricalFormula?: string | null, casNumber?: string | null, molecularWeight?: string | null, erpType?: Array<string | null> | null, substanceKey?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, image: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } } | null };

export type MaterialDetailFragment = { __typename?: 'Material', id: string, number: string, name?: string | null, isMarketplace?: boolean | null, displaySellerName?: string | null, marketplaceOfferId?: string | null, marketplaceSellerId?: string | null, description?: string | null, product: string, packageSize?: string | null, shipsToday?: boolean | null, freeFreight?: boolean | null, linearFormula?: string | null, empiricalFormula?: string | null, casNumber?: string | null, molecularWeight?: string | null, erpType?: Array<string | null> | null, substanceKey?: string | null, brand: { __typename?: 'Brand', key: string, erpKey: string, name?: string | null, color?: string | null }, image: { __typename?: 'CatalogImage', altText?: string | null, smallUrl: string, mediumUrl: string, largeUrl: string } };

export const MaterialDetailFragmentDoc = gql`
    fragment MaterialDetail on Material {
  id
  number
  name
  isMarketplace
  displaySellerName
  marketplaceOfferId
  marketplaceSellerId
  description
  brand {
    key
    erpKey
    name
    color
  }
  product
  image {
    altText
    smallUrl
    mediumUrl
    largeUrl
  }
  packageSize
  shipsToday
  freeFreight
  linearFormula
  empiricalFormula
  casNumber
  molecularWeight
  erpType
  substanceKey
}
    `;
export const MaterialDetailDocument = gql`
    query MaterialDetail($materialNumber: String!, $catalogType: CatalogType, $filter: String) {
  getMaterialDetail(input: {materialNumber: $materialNumber, catalogType: $catalogType, filter: $filter}) {
    ...MaterialDetail
  }
}
    ${MaterialDetailFragmentDoc}`;
export type MaterialDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MaterialDetailQuery, MaterialDetailQueryVariables>, 'query'> & ({ variables: MaterialDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MaterialDetailComponent = (props: MaterialDetailComponentProps) => (
      <ApolloReactComponents.Query<MaterialDetailQuery, MaterialDetailQueryVariables> query={MaterialDetailDocument} {...props} />
    );
    

/**
 * __useMaterialDetailQuery__
 *
 * To run a query within a React component, call `useMaterialDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialDetailQuery({
 *   variables: {
 *      materialNumber: // value for 'materialNumber'
 *      catalogType: // value for 'catalogType'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMaterialDetailQuery(baseOptions: ApolloReactHooks.QueryHookOptions<MaterialDetailQuery, MaterialDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MaterialDetailQuery, MaterialDetailQueryVariables>(MaterialDetailDocument, options);
      }
export function useMaterialDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MaterialDetailQuery, MaterialDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MaterialDetailQuery, MaterialDetailQueryVariables>(MaterialDetailDocument, options);
        }
export type MaterialDetailQueryHookResult = ReturnType<typeof useMaterialDetailQuery>;
export type MaterialDetailLazyQueryHookResult = ReturnType<typeof useMaterialDetailLazyQuery>;
export type MaterialDetailQueryResult = ApolloReactCommon.QueryResult<MaterialDetailQuery, MaterialDetailQueryVariables>;