// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type CreateSharedListMutationVariables = Types.Exact<{
  input: Types.CreateSharedListInput;
}>;


export type CreateSharedListMutation = { __typename?: 'Mutation', createSharedList: { __typename?: 'SharedListDetail', listId: string } };

export type UpdateSharedListMutationVariables = Types.Exact<{
  input: Types.UpdateSharedListInput;
}>;


export type UpdateSharedListMutation = { __typename?: 'Mutation', updateSharedList: boolean };

export type DeleteSharedListMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
}>;


export type DeleteSharedListMutation = { __typename?: 'Mutation', deleteSharedList: boolean };

export type DeleteSharedListMembersMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
  members: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type DeleteSharedListMembersMutation = { __typename?: 'Mutation', deleteSharedListMembers: boolean };

export type DeleteSharedListInvitationsMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
  invitations: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type DeleteSharedListInvitationsMutation = { __typename?: 'Mutation', deleteSharedListInvitations: boolean };

export type CreateSharedListItemsMutationVariables = Types.Exact<{
  listIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
  listItems: Array<Types.SharedListItemInput> | Types.SharedListItemInput;
}>;


export type CreateSharedListItemsMutation = { __typename?: 'Mutation', createSharedListItems: boolean };

export type UpdateSharedListItemMutationVariables = Types.Exact<{
  input: Types.UpdateSharedListItemInput;
}>;


export type UpdateSharedListItemMutation = { __typename?: 'Mutation', updateSharedListItem: boolean };

export type RemoveSharedListItemMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
  itemId: Types.Scalars['ID'];
}>;


export type RemoveSharedListItemMutation = { __typename?: 'Mutation', removeSharedListItem: boolean };

export type ClearSharedListItemsMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
}>;


export type ClearSharedListItemsMutation = { __typename?: 'Mutation', clearSharedListItems: boolean };

export type UpdateMembersDoneAddingToSharedListMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID'];
}>;


export type UpdateMembersDoneAddingToSharedListMutation = { __typename?: 'Mutation', updateMembersDoneAddingToSharedList: boolean };


export const CreateSharedListDocument = gql`
    mutation CreateSharedList($input: CreateSharedListInput!) {
  createSharedList(input: $input) {
    listId
  }
}
    `;
export type CreateSharedListMutationFn = ApolloReactCommon.MutationFunction<CreateSharedListMutation, CreateSharedListMutationVariables>;
export type CreateSharedListComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedListMutation, CreateSharedListMutationVariables>, 'mutation'>;

    export const CreateSharedListComponent = (props: CreateSharedListComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedListMutation, CreateSharedListMutationVariables> mutation={CreateSharedListDocument} {...props} />
    );
    

/**
 * __useCreateSharedListMutation__
 *
 * To run a mutation, you first call `useCreateSharedListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedListMutation, { data, loading, error }] = useCreateSharedListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedListMutation, CreateSharedListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSharedListMutation, CreateSharedListMutationVariables>(CreateSharedListDocument, options);
      }
export type CreateSharedListMutationHookResult = ReturnType<typeof useCreateSharedListMutation>;
export type CreateSharedListMutationResult = ApolloReactCommon.MutationResult<CreateSharedListMutation>;
export type CreateSharedListMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedListMutation, CreateSharedListMutationVariables>;
export const UpdateSharedListDocument = gql`
    mutation UpdateSharedList($input: UpdateSharedListInput!) {
  updateSharedList(input: $input)
}
    `;
export type UpdateSharedListMutationFn = ApolloReactCommon.MutationFunction<UpdateSharedListMutation, UpdateSharedListMutationVariables>;
export type UpdateSharedListComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSharedListMutation, UpdateSharedListMutationVariables>, 'mutation'>;

    export const UpdateSharedListComponent = (props: UpdateSharedListComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSharedListMutation, UpdateSharedListMutationVariables> mutation={UpdateSharedListDocument} {...props} />
    );
    

/**
 * __useUpdateSharedListMutation__
 *
 * To run a mutation, you first call `useUpdateSharedListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharedListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharedListMutation, { data, loading, error }] = useUpdateSharedListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharedListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSharedListMutation, UpdateSharedListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSharedListMutation, UpdateSharedListMutationVariables>(UpdateSharedListDocument, options);
      }
export type UpdateSharedListMutationHookResult = ReturnType<typeof useUpdateSharedListMutation>;
export type UpdateSharedListMutationResult = ApolloReactCommon.MutationResult<UpdateSharedListMutation>;
export type UpdateSharedListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSharedListMutation, UpdateSharedListMutationVariables>;
export const DeleteSharedListDocument = gql`
    mutation DeleteSharedList($listId: ID!) {
  deleteSharedList(listId: $listId)
}
    `;
export type DeleteSharedListMutationFn = ApolloReactCommon.MutationFunction<DeleteSharedListMutation, DeleteSharedListMutationVariables>;
export type DeleteSharedListComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSharedListMutation, DeleteSharedListMutationVariables>, 'mutation'>;

    export const DeleteSharedListComponent = (props: DeleteSharedListComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSharedListMutation, DeleteSharedListMutationVariables> mutation={DeleteSharedListDocument} {...props} />
    );
    

/**
 * __useDeleteSharedListMutation__
 *
 * To run a mutation, you first call `useDeleteSharedListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedListMutation, { data, loading, error }] = useDeleteSharedListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useDeleteSharedListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSharedListMutation, DeleteSharedListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSharedListMutation, DeleteSharedListMutationVariables>(DeleteSharedListDocument, options);
      }
export type DeleteSharedListMutationHookResult = ReturnType<typeof useDeleteSharedListMutation>;
export type DeleteSharedListMutationResult = ApolloReactCommon.MutationResult<DeleteSharedListMutation>;
export type DeleteSharedListMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSharedListMutation, DeleteSharedListMutationVariables>;
export const DeleteSharedListMembersDocument = gql`
    mutation DeleteSharedListMembers($listId: ID!, $members: [ID!]!) {
  deleteSharedListMembers(listId: $listId, members: $members)
}
    `;
export type DeleteSharedListMembersMutationFn = ApolloReactCommon.MutationFunction<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables>;
export type DeleteSharedListMembersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables>, 'mutation'>;

    export const DeleteSharedListMembersComponent = (props: DeleteSharedListMembersComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables> mutation={DeleteSharedListMembersDocument} {...props} />
    );
    

/**
 * __useDeleteSharedListMembersMutation__
 *
 * To run a mutation, you first call `useDeleteSharedListMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedListMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedListMembersMutation, { data, loading, error }] = useDeleteSharedListMembersMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      members: // value for 'members'
 *   },
 * });
 */
export function useDeleteSharedListMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables>(DeleteSharedListMembersDocument, options);
      }
export type DeleteSharedListMembersMutationHookResult = ReturnType<typeof useDeleteSharedListMembersMutation>;
export type DeleteSharedListMembersMutationResult = ApolloReactCommon.MutationResult<DeleteSharedListMembersMutation>;
export type DeleteSharedListMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSharedListMembersMutation, DeleteSharedListMembersMutationVariables>;
export const DeleteSharedListInvitationsDocument = gql`
    mutation DeleteSharedListInvitations($listId: ID!, $invitations: [String!]!) {
  deleteSharedListInvitations(listId: $listId, invitations: $invitations)
}
    `;
export type DeleteSharedListInvitationsMutationFn = ApolloReactCommon.MutationFunction<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables>;
export type DeleteSharedListInvitationsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables>, 'mutation'>;

    export const DeleteSharedListInvitationsComponent = (props: DeleteSharedListInvitationsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables> mutation={DeleteSharedListInvitationsDocument} {...props} />
    );
    

/**
 * __useDeleteSharedListInvitationsMutation__
 *
 * To run a mutation, you first call `useDeleteSharedListInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedListInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedListInvitationsMutation, { data, loading, error }] = useDeleteSharedListInvitationsMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      invitations: // value for 'invitations'
 *   },
 * });
 */
export function useDeleteSharedListInvitationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables>(DeleteSharedListInvitationsDocument, options);
      }
export type DeleteSharedListInvitationsMutationHookResult = ReturnType<typeof useDeleteSharedListInvitationsMutation>;
export type DeleteSharedListInvitationsMutationResult = ApolloReactCommon.MutationResult<DeleteSharedListInvitationsMutation>;
export type DeleteSharedListInvitationsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSharedListInvitationsMutation, DeleteSharedListInvitationsMutationVariables>;
export const CreateSharedListItemsDocument = gql`
    mutation CreateSharedListItems($listIds: [ID!]!, $listItems: [SharedListItemInput!]!) {
  createSharedListItems(listIds: $listIds, listItems: $listItems)
}
    `;
export type CreateSharedListItemsMutationFn = ApolloReactCommon.MutationFunction<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables>;
export type CreateSharedListItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables>, 'mutation'>;

    export const CreateSharedListItemsComponent = (props: CreateSharedListItemsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables> mutation={CreateSharedListItemsDocument} {...props} />
    );
    

/**
 * __useCreateSharedListItemsMutation__
 *
 * To run a mutation, you first call `useCreateSharedListItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedListItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedListItemsMutation, { data, loading, error }] = useCreateSharedListItemsMutation({
 *   variables: {
 *      listIds: // value for 'listIds'
 *      listItems: // value for 'listItems'
 *   },
 * });
 */
export function useCreateSharedListItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables>(CreateSharedListItemsDocument, options);
      }
export type CreateSharedListItemsMutationHookResult = ReturnType<typeof useCreateSharedListItemsMutation>;
export type CreateSharedListItemsMutationResult = ApolloReactCommon.MutationResult<CreateSharedListItemsMutation>;
export type CreateSharedListItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedListItemsMutation, CreateSharedListItemsMutationVariables>;
export const UpdateSharedListItemDocument = gql`
    mutation UpdateSharedListItem($input: UpdateSharedListItemInput!) {
  updateSharedListItem(input: $input)
}
    `;
export type UpdateSharedListItemMutationFn = ApolloReactCommon.MutationFunction<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables>;
export type UpdateSharedListItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables>, 'mutation'>;

    export const UpdateSharedListItemComponent = (props: UpdateSharedListItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables> mutation={UpdateSharedListItemDocument} {...props} />
    );
    

/**
 * __useUpdateSharedListItemMutation__
 *
 * To run a mutation, you first call `useUpdateSharedListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSharedListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSharedListItemMutation, { data, loading, error }] = useUpdateSharedListItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSharedListItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables>(UpdateSharedListItemDocument, options);
      }
export type UpdateSharedListItemMutationHookResult = ReturnType<typeof useUpdateSharedListItemMutation>;
export type UpdateSharedListItemMutationResult = ApolloReactCommon.MutationResult<UpdateSharedListItemMutation>;
export type UpdateSharedListItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSharedListItemMutation, UpdateSharedListItemMutationVariables>;
export const RemoveSharedListItemDocument = gql`
    mutation RemoveSharedListItem($listId: ID!, $itemId: ID!) {
  removeSharedListItem(listId: $listId, itemId: $itemId)
}
    `;
export type RemoveSharedListItemMutationFn = ApolloReactCommon.MutationFunction<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables>;
export type RemoveSharedListItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables>, 'mutation'>;

    export const RemoveSharedListItemComponent = (props: RemoveSharedListItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables> mutation={RemoveSharedListItemDocument} {...props} />
    );
    

/**
 * __useRemoveSharedListItemMutation__
 *
 * To run a mutation, you first call `useRemoveSharedListItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSharedListItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSharedListItemMutation, { data, loading, error }] = useRemoveSharedListItemMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useRemoveSharedListItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables>(RemoveSharedListItemDocument, options);
      }
export type RemoveSharedListItemMutationHookResult = ReturnType<typeof useRemoveSharedListItemMutation>;
export type RemoveSharedListItemMutationResult = ApolloReactCommon.MutationResult<RemoveSharedListItemMutation>;
export type RemoveSharedListItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveSharedListItemMutation, RemoveSharedListItemMutationVariables>;
export const ClearSharedListItemsDocument = gql`
    mutation ClearSharedListItems($listId: ID!) {
  clearSharedListItems(listId: $listId)
}
    `;
export type ClearSharedListItemsMutationFn = ApolloReactCommon.MutationFunction<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables>;
export type ClearSharedListItemsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables>, 'mutation'>;

    export const ClearSharedListItemsComponent = (props: ClearSharedListItemsComponentProps) => (
      <ApolloReactComponents.Mutation<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables> mutation={ClearSharedListItemsDocument} {...props} />
    );
    

/**
 * __useClearSharedListItemsMutation__
 *
 * To run a mutation, you first call `useClearSharedListItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearSharedListItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearSharedListItemsMutation, { data, loading, error }] = useClearSharedListItemsMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useClearSharedListItemsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables>(ClearSharedListItemsDocument, options);
      }
export type ClearSharedListItemsMutationHookResult = ReturnType<typeof useClearSharedListItemsMutation>;
export type ClearSharedListItemsMutationResult = ApolloReactCommon.MutationResult<ClearSharedListItemsMutation>;
export type ClearSharedListItemsMutationOptions = ApolloReactCommon.BaseMutationOptions<ClearSharedListItemsMutation, ClearSharedListItemsMutationVariables>;
export const UpdateMembersDoneAddingToSharedListDocument = gql`
    mutation UpdateMembersDoneAddingToSharedList($listId: ID!) {
  updateMembersDoneAddingToSharedList(listId: $listId)
}
    `;
export type UpdateMembersDoneAddingToSharedListMutationFn = ApolloReactCommon.MutationFunction<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables>;
export type UpdateMembersDoneAddingToSharedListComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables>, 'mutation'>;

    export const UpdateMembersDoneAddingToSharedListComponent = (props: UpdateMembersDoneAddingToSharedListComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables> mutation={UpdateMembersDoneAddingToSharedListDocument} {...props} />
    );
    

/**
 * __useUpdateMembersDoneAddingToSharedListMutation__
 *
 * To run a mutation, you first call `useUpdateMembersDoneAddingToSharedListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembersDoneAddingToSharedListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembersDoneAddingToSharedListMutation, { data, loading, error }] = useUpdateMembersDoneAddingToSharedListMutation({
 *   variables: {
 *      listId: // value for 'listId'
 *   },
 * });
 */
export function useUpdateMembersDoneAddingToSharedListMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables>(UpdateMembersDoneAddingToSharedListDocument, options);
      }
export type UpdateMembersDoneAddingToSharedListMutationHookResult = ReturnType<typeof useUpdateMembersDoneAddingToSharedListMutation>;
export type UpdateMembersDoneAddingToSharedListMutationResult = ApolloReactCommon.MutationResult<UpdateMembersDoneAddingToSharedListMutation>;
export type UpdateMembersDoneAddingToSharedListMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMembersDoneAddingToSharedListMutation, UpdateMembersDoneAddingToSharedListMutationVariables>;